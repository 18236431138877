// Context
// -------------------------
$context: "";
$summary-cards-sm-min-height: initial;

//== Logos
$logo-bg: url("#{$context}/images/brand/dairyland/logo.png") 4px 8px no-repeat;
$logo-width: 162px;
$logo-height: 76px;

// Style Guide Colors
// -------------------------
$primary-dark-blue: 		#003766;
$primary-dark-gray: 		#636363;
$primary-gray: 				#cccccc;
$primary-light-gray: 		#ededed;
$secondary-yellow: 			#ffb114;
$secondary-red: 			#c12820;
$secondary-orange: 			#f57211;
$secondary-bright-blue: 	#77b7f5;
$secondary-blue-gray: 		#ccd7e0;
$secondary-dark-blue: 		#010432;

$brand-failure:			#C1282D;
$fontcolor-brand-primary-bg		: #FFFFFF;
$brand-gray-lighter:			#EAEAEA;
$brand-gray-lightest:			#F3F3F3;


$dairyland-white: #FFF;
$dairyland-asphalt: #2F3840;
$dairyland-forest: #3F5630;
$dairyland-slate: #365D76;
$dairyland-brick: #701E1A;
$dairyland-dusk: #393961;
$dairyland-lightdusk: #C4C4D0;
$dairyland-concrete: #697179;
$dairyland-olive: #C6BE28;
$dairyland-sky: #79D2E5;
$dairyland-fire: #F34930;
$dairyland-indigo: #361D5D;
$dairyland-alt-link: #F34930;
$dairyland-gray-copy: #E1E2E4;
$dairyland-light-copy: #BCE8F2;
$dairyland-ghosted-input-color: #697179;
$dairyland-active-copy-color: #2D6882;
$dairyland-foamy-milk-aka-bright-indigo-five-percent: #f7f4fa;
$dairyland-background-fill-color: $dairyland-lightdusk;
$dairyland-tint-sky-20: #E4F6FA;