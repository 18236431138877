// --------------------------------------------------
// Bootstrap3 Variables
// --------------------------------------------------
$gray:                           $primary-gray;
$gray-light:                     lighten(#000, 60%);   // #999
$gray-lighter:                   $primary-light-gray;
$font-size-h2:                   19px;
$border-radius-base:             0;
$border-radius-small:            $btn-border-radius;
$label-color:                    $dairyland-asphalt;
$cursor-disabled:                not-allowed;
$btn-primary-color:              $fontcolor-brand-primary-bg;
$btn-primary-bg:                 $primary;
$btn-primary-border:             darken($btn-primary-bg, 5%);
$input-border:                   $secondary;
$state-success-text:             darken($success, 20%);
$state-success-bg:               lighten($state-success-text, 60%);
$state-success-border:           $dairyland-active-copy-color;
$state-info-text:                darken($info, 10%);
$state-info-bg:                  lighten($state-info-text, 69%);
$state-info-border:              lighten($state-info-text, 59%);
$state-warning-text:             darken($warning, 25%);
$state-warning-bg:               lighten($state-warning-text, 70%);
$state-warning-border:           lighten($state-warning-text, 60%);
$state-danger-text:              $danger;
$state-danger-bg:                lighten($state-danger-text, 56%);
$state-danger-border:            lighten($state-danger-text, 46%);
$navbar-default-link-color:      #777;
$dropdown-border:                rgba(0,0,0,.15);
$state-info-text:                darken($info, 10%);
$state-info-bg:                  lighten($state-info-text, 69%);
// --------------------------------------------------
// End Bootstrap 3 Variables
// --------------------------------------------------

$dairyland-font-path:        "../dairyland/fonts/";
$dairyland-icons-path:       "../thirdparty/dairyland-icons/fonts/";

@font-face {
    font-family: "DairylandIcons";
    src:url('#{$dairyland-icons-path}dairylandicons.eot');
    src:url('#{$dairyland-icons-path}dairylandicons.eot?#iefix') format('embedded-opentype'),
        url('#{$dairyland-icons-path}dairylandicons.woff') format('woff'),
        url('#{$dairyland-icons-path}dairylandicons.ttf') format('truetype'),
        url('#{$dairyland-icons-path}dairylandicons.svg#') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "GeogrotesqueSentryBold";
    src:     url("#{$dairyland-font-path}GeSe1000-Bd.eot?#iefix");
    src: url("#{$dairyland-font-path}GeSe1000-Bd.eot?#iefix") format("eot"),
         url("#{$dairyland-font-path}GeSe1000-Bd.woff2") format("woff2"),
         url("#{$dairyland-font-path}GeSe1000-Bd.woff") format("woff"),
         url("#{$dairyland-font-path}GeSe1000-Bd.ttf") format("truetype"),
         url("#{$dairyland-font-path}GeSe1000-Bd.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GeogrotesqueSentryBoldItalic";
    src:     url("#{$dairyland-font-path}GeSe1000-BdIt.eot?#iefix");
    src: url("#{$dairyland-font-path}GeSe1000-BdIt.eot?#iefix") format("eot"),
         url("#{$dairyland-font-path}GeSe1000-BdIt.woff2") format("woff2"),
         url("#{$dairyland-font-path}GeSe1000-BdIt.woff") format("woff"),
         url("#{$dairyland-font-path}GeSe1000-BdIt.ttf") format("truetype"),
         url("#{$dairyland-font-path}GeSe1000-BdIt.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "GeogrotesqueSentryLight";
    src:     url("#{$dairyland-font-path}GeSe1000-Lt.eot?#iefix");
    src: url("#{$dairyland-font-path}GeSe1000-Lt.eot?#iefix") format("eot"),
         url("#{$dairyland-font-path}GeSe1000-Lt.woff2") format("woff2"),
         url("#{$dairyland-font-path}GeSe1000-Lt.woff") format("woff"),
         url("#{$dairyland-font-path}GeSe1000-Lt.ttf") format("truetype"),
         url("#{$dairyland-font-path}GeSe1000-Lt.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "GeogrotesqueSentryLightItalic";
    src:     url("#{$dairyland-font-path}GeSe1000-LtIt.eot?#iefix");
    src: url("#{$dairyland-font-path}GeSe1000-LtIt.eot?#iefix") format("eot"),
         url("#{$dairyland-font-path}GeSe1000-LtIt.woff2") format("woff2"),
         url("#{$dairyland-font-path}GeSe1000-LtIt.woff") format("woff"),
         url("#{$dairyland-font-path}GeSe1000-LtIt.ttf") format("truetype"),
         url("#{$dairyland-font-path}GeSe1000-LtIt.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GeogrotesqueSentryMedium";
    src:     url("#{$dairyland-font-path}GeSe1000-Md.eot?#iefix");
    src: url("#{$dairyland-font-path}GeSe1000-Md.eot?#iefix") format("eot"),
         url("#{$dairyland-font-path}GeSe1000-Md.woff2") format("woff2"),
         url("#{$dairyland-font-path}GeSe1000-Md.woff") format("woff"),
         url("#{$dairyland-font-path}GeSe1000-Md.ttf") format("truetype"),
         url("#{$dairyland-font-path}GeSe1000-Md.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
font-family: 'GeogrotesqueSentryMediumItalic';
      src: url('#{$dairyland-font-path}GeSe1000-MdIt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-MdIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-MdIt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-MdIt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-MdIt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-MdIt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}


@font-face {
font-family: 'GeogrotesqueSentryRegular';
      src: url('#{$dairyland-font-path}GeSe1000-Rg.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-Rg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-Rg.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-Rg.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-Rg.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-Rg.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}


@font-face {
font-family: 'GeogrotesqueSentryRegularItalic';
      src: url('#{$dairyland-font-path}GeSe1000-RgIt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-RgIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-RgIt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-RgIt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-RgIt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-RgIt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}


@font-face {
font-family: 'GeogrotesqueSentrySemiBold';
      src: url('#{$dairyland-font-path}GeSe1000-SmBd.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-SmBd.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-SmBd.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-SmBd.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-SmBd.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-SmBd.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}




@font-face {
font-family: 'GeogrotesqueSentrySemiBoldItalic';
      src: url('#{$dairyland-font-path}GeSe1000-SmBdIt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-SmBdIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-SmBdIt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-SmBdIt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-SmBdIt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-SmBdIt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}


@font-face {
font-family: 'GeogrotesqueSentryThin';
      src: url('#{$dairyland-font-path}f/GeSe1000-Th.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-Th.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-Th.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-Th.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-Th.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-Th.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}



@font-face {
font-family: 'GeogrotesqueSentryThinItalic';
      src: url('#{$dairyland-font-path}GeSe1000-ThIt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-ThIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}f/GeSe1000-ThIt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-ThIt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-ThIt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-ThIt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}


@font-face {
font-family: 'GeogrotesqueSentryUltraLight';
      src: url('#{$dairyland-font-path}GeSe1000-UltLt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-UltLt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-UltLt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-UltLt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-UltLt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-UltLt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}



@font-face {
font-family: 'GeogrotesqueSentryUltraLightItalic';
      src: url('#{$dairyland-font-path}GeSe1000-UltLtIt.eot'); /* IE9 Compat Modes */
      src: url('#{$dairyland-font-path}GeSe1000-UltLtIt.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
           url('#{$dairyland-font-path}GeSe1000-UltLtIt.woff2') format('woff2'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-UltLtIt.woff') format('woff'), /* Modern Browsers */
           url('#{$dairyland-font-path}GeSe1000-UltLtIt.ttf') format('truetype'), /* Safari, Android, iOS */
           url('#{$dairyland-font-path}GeSe1000-UltLtIt.svg#font') format('svg'); /* Legacy iOS */
      font-style: normal;
      font-weight: normal;
      text-rendering: optimizeLegibility;
}

$font-family-geogrotesque-bold: "GeogrotesqueSentryBold", sans-serif;
$font-family-geogrotesque-bold-italic: "GeogrotesqueSentryBoldItalic", sans-serif;
$font-family-geogrotesque-light: "GeogrotesqueSentryLight", sans-serif;
$font-family-geogrotesque-light-italic: "GeogrotesqueSentryLightItalic", sans-serif;
$font-family-geogrotesque-medium: "GeogrotesqueSentryMedium", sans-serif;
$font-family-geogrotesque-medium-italic: "GeogrotesqueSentryMediumItalic", sans-serif;
$font-family-geogrotesque-regular: "GeogrotesqueSentryRegular", sans-serif;
$font-family-geogrotesque-regular-italic: "GeogrotesqueSentryRegularItalic", sans-serif;
$font-family-geogrotesque-semi-bold: "GeogrotesqueSentrySemiBold", sans-serif;
$font-family-geogrotesque-semi-bold-italic: "GeogrotesqueSentrySemiBoldItalic", sans-serif;
$font-family-geogrotesque-thin: "GeogrotesqueSentryThin", sans-serif;
$font-family-geogrotesque-thin-italic: "GeogrotesqueSentryThinItalic", sans-serif;
$font-family-geogrotesque-ultralight: "GeogrotesqueSentryUltraLight", sans-serif;
$font-family-geogrotesque-ultralight-italic: "GeogrotesqueSentryUltraLightItalic", sans-serif;
$font-family-semi-bold: "GeogrotesqueSentrySemiBold", sans-serif;
$font-family-regular: "GeogrotesqueSentryRegular", sans-serif;

$typography-active-copy-color: #2D6882;

.typography-h1 {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 32px;
    line-height: 42px;
    font-weight: normal;
    color: $label-color;
    @include media-breakpoint-down(sm) {
		font-size: 26px;
        line-height: 34px;
	}
}
%typography-h2 {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 28px;
	line-height: 36px;
    font-weight: normal;
    color: $primary;

    @include media-breakpoint-down(sm) {
		font-size: 20px;
        line-height: 26px;
	}
}

%typography-h3 {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 24px;
	line-height: 31px;
    font-weight: normal;
    color: $primary;
}
.typography-h4 {
	font-family: $font-family-geogrotesque-bold;
	font-size: 20px;
	line-height: 26px;
    font-weight: normal;
}
.typography-body-copy {
    font-family: $font-family-geogrotesque-medium;
    font-size: 18px;
    line-height: 23px;
}
.typography-body-copy-bold {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 18px;
    line-height: 23px;
}
.typography-body-copy-medium {
    font-family: $font-family-geogrotesque-medium;
    font-size: 16px;
    line-height: 21px;
}
.typography-body-copy-small {
    font-family: $font-family-geogrotesque-medium;
    font-size: 15px;
    line-height: 21px;
}
.typography-column-header {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 14px;
    line-height: 18px;
}
.typography-tooltip-copy {
    font-family: $font-family-geogrotesque-medium;
    font-size: 14px;
    line-height: 18px;
}
.typography-disclaimer {
    font-family: $font-family-geogrotesque-medium;
    font-size: 12px;
    line-height: 16px;
}
.typography-footer-text {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 12px;
	line-height: 18px;
	color: $primary;
}
.typography-warning-text {
	font-family: $font-family-geogrotesque-medium;
	font-size: 15px;
	line-height: 20px;
	color: $danger;
}
.typography-text-link-large {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 20px;
	line-height: 26px;
	text-decoration: none;
	color: $typography-active-copy-color;

	&:hover , &:focus, &:active {
        text-decoration: underline;
        color: $dairyland-asphalt;
	}
}
.typography-text-link:not(.icon-QTE-tooltip) {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 16px;
	line-height: 21px;
	text-decoration: none;
	color: $typography-active-copy-color;
}
.typography-text-link-small {
	font-family: $font-family-geogrotesque-semi-bold;
	font-size: 12px;
	line-height: 16px;
    text-decoration: none;
}
.typography-text-link-alt {
    font-family: $font-family-geogrotesque-semi-bold;
	font-size: 16px;
	line-height: 21px;
    text-decoration: none;
    color: $dairyland-light-copy;
}
.typography-text-link-small-alt {
    font-family: $font-family-geogrotesque-semi-bold;
	font-size: 12px;
	line-height: 16px;
    text-decoration: none;
    color: $dairyland-light-copy;
}
.typography-input-ghosted {
	font-family: $font-family-geogrotesque-medium;
	font-size: 16px;
	line-height: 21px;
}
.typography-input-active-complete {
	font-family: $font-family-geogrotesque-medium;
	font-size: 16px;
	line-height: 21px;
	color: $primary;
}
.typography-card-active {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 14px;
	line-height: 18px;
    color: $white;
    margin: 0;
}
.typography-card-inactive {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 14px;
	line-height: 18px;
    color: $dairyland-concrete;
    margin: 0;
}
.typography-card-complete {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 18px;
	line-height: 23px;
    color: $dairyland-concrete;
    margin: 0;
}
.typography-card-incomplete {
    font-family: $font-family-geogrotesque-semi-bold;
    font-size: 18px;
	line-height: 23px;
    color: $white;
    margin: 0;
}
.typography-field-label {
	font-family: $font-family-geogrotesque-medium;
	font-size: 14px;
	font-weight: 400;
	color: $dairyland-asphalt;
}

$font-size-large-lg:	  28px;
$fontsize-standard-sm: 	1.0em;
$fontsize-standard: 	1.2em;
$fontsize-standard-lg: 	1.4em;
$fontsize-small-sm:		.6em;
$font-weight-large:		400;

$brand-primary:         $primary;
$brand-danger:          $danger;
$brand-warning:         $warning;
$brand-info:            $info;
$brand-success:         $success;
$brand-secondary:		$secondary-blue-gray;
$brand-complementary-1:	#fdbb16;

//----------------------------------------------------------------
// CUSTOM VARIABLES
// These are custom variables not used in Bootstrap framework
//----------------------------------------------------------------
$background-image: 				none;
$brand-gray-light:				#ebeced;
$gray-base-fontcolor: 			#FFFFFF;
$pre-border-color:              $dairyland-ghosted-input-color;

$brand-brown-dark:				#513a0c;

$toggle-button-width:           80px;

$card-active-bg:				$dairyland-asphalt;
$card-status-color-active:		$dairyland-asphalt;

$card-padding: 					7px 0 8px 0;
$card-border-radius:			$border-radius-sm;
$card-border-color-active:      $card-status-color-active;
$card-add-new-entity-bg: transparent;
$card-active-border-width:		2px;
$card-header-leading:			20px;
$card-header-fontsize:			13px;
$card-header-color:				$white;
$card-header-marginbottom:		0px;
$card-header-color-inactive:	$primary-dark-gray;
$card-header-color-hover:		$secondary-yellow;

$card-subhead-leading: 			15px;
$card-subhead-marginbottom:		5px;
$card-subhead-fontsize:			13px;
$card-subhead-color:			$white;
$card-subhead-color-inactive:	$dairyland-concrete;
$card-subhead-color-hover:		$secondary-yellow;

$card-status-leading:			18px;
$card-status-fontsize: 			$card-subhead-fontsize;
$card-status-color-complete: 	$dairyland-concrete;
$card-status-color-incomplete:	$white;
$card-status-color-inactive:	$primary-dark-gray;
$card-status-marginbottom:		0px;
$card-status-color-hover:		$secondary-yellow;
$card-status-selected-checkmark-color:	$white;
$card-status-has-error-color:	$danger;

$card-border-color:				$primary-dark-gray;
$card-summary-border-color:		$dairyland-asphalt;
$card-summary-color:			$primary-dark-gray;
$card-edit-icon-color:			$dairyland-light-copy;
$card-link-color:				$primary-dark-gray;

$disclaimer-fontsize:			11px;
$disclaimer-weight:				normal;
$disclaimer-color:				$primary-dark-gray;

$auto-payments-question-margin-top: 1.5rem;

$header2-fontsize:				19px;
$header2-color:					$primary;
$header2-weight:				bold;

$body-fontsize:					14px;
$body-color:					$dairyland-asphalt;
$body-weight:					normal;
$body-lineheight:				20px;

$tooltip-header-weight:			bold;
$tooltip-header-fontsize:		15px;
$tooltip-header-color:			$dairyland-asphalt;
$tooltip-copy-weight:			normal;
$tooltip-copy-fontsize:			15px;
$tooltip-copy-color:			$primary;

$tooltip-content-width:			350px;

$formheader-fontsize:			12px;
$formheader-color:				$dairyland-asphalt;
$formheader-bg-color:           $dairyland-background-fill-color;
$formheader-weight:				bold;

$formquestion-copy-weight:		normal;
$formquestion-copy-fontsize:	1rem;
$formquestion-copy-color:		$primary-dark-gray;

$paperless-modal-header-weight: bold;
$paperless-modal-header-font-size: 24px;
$paperless-modal-header-margin: 20px;

$quote-content-background-color: transparent;
$quote-content-box-shadow: none;

$sidebar-active-bg: $dairyland-white;

$calendar-icon-color:  			$primary-dark-gray;

//-------------------------------------------
// Tables
//-------------------------------------------
$table-heading-padding:			10px 8px;

//-------------------------------------------
// Data Tables
//-------------------------------------------
$filter-icon-width:				20px;

$content-padding-top: 				0;
$content-padding-bottom: 			40px;

//-------------------------------------------
// Buttons
//-------------------------------------------

$primary-button-bg:				$primary;
$primary-button-color: 			$fontcolor-brand-primary-bg;
$primary-button-border:			$primary;

$secondary-button-color:       	#000;//#fff;
$secondary-button-bg:          	$brand-secondary;
$secondary-button-border:      	darken($secondary-button-bg, 5%);

$complementary-button-color: 	#000;
$complementary-button-bg: 		$brand-complementary-1;
$complementary-button-border: 	$brand-complementary-1;

$success-button-bg: 			$success;
$success-button-color: 			#FFFFFF;
$success-button-border: 		$success;

$btn-setting-off: 				#fff;
$btn-setting-off-bg: 			#FF0000;
$btn-setting-off-border: 		darken($btn-setting-off-bg, 5%);

$btn-setting-on: 				#fff;
$btn-setting-on-bg: 			green;
$btn-setting-on-border: 		darken($btn-setting-on-bg, 5%);

$btn-action-color: 				#ffffff;
$btn-action-bg:		 			$secondary;
$btn-action-border: 			$dairyland-slate;
$btn-action-hover-color:		$dairyland-asphalt;

$btn-disabled: 					#ACACAC;
$btn-disabled-bg: 				#FFFFFF;
$btn-disabled-border: 			#8A8A8A;

$btn-add-new-entity-color:		$primary-dark-blue;
$btn-add-card-color:			$primary-dark-blue;
$btn-add-card-color-hover:		$secondary-yellow;
$add-entity-btn-background-color: #fff;

$btn-add-entity-bg:			transparent;
$btn-add-entity-border:		inherit;

$disable-btn-color: $dairyland-concrete;

$summary-button-margin-top:		5px;
//-----------------------------------
// Button Group - radio buttons
//-----------------------------------
$btn-radio-default-color:						$dairyland-asphalt;
$btn-radio-default-bg-color:					$btn-action-color;
$btn-radio-default-color-hover:                 $secondary;
$btn-radio-default-bg-color-hover:				transparent;
$btn-radio-default-bg-color-active:				$white;
$btn-radio-default-bg-color-active-box-shadow:	$brand-brown-dark;
$btn-radio-active-box-shadow:                   rgba(54,29,93,0.5);
$has-error-button-group-height:                 auto;

//-----------------------------------
// Remove button
//-----------------------------------
$btn-remove-entry-color:		$dairyland-asphalt;
$btn-remove-entry-icon-color:	$danger;

//------------------------------------
// Alert
//------------------------------------
$alert-warning-bg-color:		$secondary-orange;

//------------------------------------
// Modals
//------------------------------------
$modal-content-link-color:		    $primary-dark-blue;
$modal-header-bg-color:			    $white;
$modal-close-btn-variant-color:		$white;
$modal-close-btn-variant-bg:		$primary;
$modal-close-btn-variant-border:	#FFF;

//-----------------------------------
// Quote
//-----------------------------------
$accordion-border:					2px solid #e6e6e6;
$quote-summary-affix-box-shadow:	transparent;
$quote-container-bg:				#ffffff;
$quote-side-margin:					15px;

//-----------------------------------
// Quote Final Sale
//-----------------------------------
$finalsale-payment-option-bg:		#fff;
$finalsale-payment-option-inactive-border-color: $brand-gray-light;
$container-iframe-height: 1455px;
$container-iframe-height-md: 1365px;

//----------------------
// Driver Page
//----------------------
$incidents-info-border: none;

//-----------------------------------
// Datepicker
//-----------------------------------
$datepicker-bg-color:			$secondary-blue-gray;
$datepicker-border-color:		$primary-dark-blue;
$datepicker-day-focus-bg-color:	$primary-dark-blue;
$datepicker-day-color:			$primary-dark-gray;
$datepicker-day-disabled-color:	$primary-dark-gray;
$datepicker-today-border-color:	$primary-dark-gray;
$datepicker-today-color-hover:	#ffffff;
$datepicker-today-bg-color:		$secondary-blue-gray;
$datepicker-today-bg-color-hover:	$primary-dark-blue;
$datepicker-range-disabled-bg-color:	$primary-light-gray;
$datepicker-td-border-radius: 	100%;
$datepicker-week-color: inherit;
$datepicker-week-border-top: 1px solid $primary;
$datepicker-week-border-bottom: 1px solid $primary;
$datepicker-week-background: transparent;
$datepicker-days-switch-color: inherit;
$today-bg: 						$secondary-yellow;
$today-color: 					#000;

//-----------------------------------
// Tooltips
//-----------------------------------
$tooltip-color:						$secondary;
$tooltip-popover-bg-color:			$secondary;
$tooltip-popover-content-color:		$white;
$tooltip-popover-content-bg-color:	$secondary;
$tooltip-arrow-color:				$secondary;
$tooltip-arrow-border-color:		$secondary;

//---------------------------------------
// Navigation
//---------------------------------------
$content-padding-top: 				0;
$content-padding-bottom: 			40px;
$navigation-link-color:				#fff;
$contact-us-button-font-size:       1.0625rem;
$iq-navbar-padding:             1.4rem;
$iq-navbar-button-padding-xs: 0.313rem;
$iq-navbar-button-font-size: 1.563rem;
$iq-navbar-button-column-padding: 0rem;
$iq-navbar-bg: $white;
$iq-navbar-button-color: $primary;

//---------------------------------------
// FAQ
//---------------------------------------
$iq-faq-close-size: 0.938rem;
$iq-faq-close-padding: 0.625rem;
$iq-close-top-margin-mod: -1.625rem;
$iq-close-right-margin-mod: -1rem;
$faq-phone-number-TB-padding: 0.625rem;
$faq-phone-number-LR-padding: 1.313rem;
$iq-faq-contact-help-padding: 0.188rem;
$faq-phone-icon-size: 1.188rem;
$faq-phone-number-size: 1.25rem;
$faq-card-border: 1px solid #697179;
$faq-contact-us-color: #2D6882;
$faq-questions-padding: 0.625rem;
$faq-title-top-padding: 1.563rem;
$faq-title-bottom-padding: 0.313rem;
$faq-down-chevron-size: 0.625rem;

//---------------------------------------
// Footer
//---------------------------------------
$footer-height-quote-xs:		145px;//270px;
$footer-height-quote-sm:		75px;//155px;
$footer-height-quote-md:		55px;//120px;
$footer-height-quote-lg:		55px;//112px;
$footer-bg: 					$dairyland-gray-copy;
$footer-setting-color: 			$primary;
$footer-link-color:				$primary;
$footer-item-padding-left:      10px;
$footer-anchor-text-decoration: none;

//---------------------------------------
// Sidenav
//---------------------------------------
$sidenav-vehicle-padding:       0.3rem;

//------------------------------------
// Quote Sidebar Affix
//------------------------------------
$quote-affix-color: 					               #fff;
$quote-affix-quote-number-font-size:                   21px;
$quote-sidebar-h4-font-size:                           36px;
$quote-affix-list-font-size:                           14px;
$quote-affix-padding: 					               10px 0 10px 0;
$quote-affix-h4-color:					               #fff;
$quote-affix-sidebar-header-border-color:	           $secondary-blue-gray;
$quote-affix-sidebar-active-arrow-color:	           #fff;
$quote-affix-sidebar-active-arrow-size:		           12px;
$quote-affix-sidebar-active-arrow-top-pos:	           9px;
$quote-affix-sidebar-list-data-color:	               $dairyland-white;
$sidebar-border-right-width: 			               10px;
$quote-affix-edit-link-color:                          $dairyland-light-copy;
$quote-affix-edit-link-hover-color:                    darken($quote-affix-edit-link-color, 15%);
$quote-affix-sidebar-purchase-summary-headers-color:   $dairyland-light-copy;
$quote-affix-sidebar-ul-margin-left:                   0;
$quote-affix-sidebar-active-list-data-color:           $dairyland-asphalt;
$quote-affix-sidebar-item-padding-left:                10px;
$quote-affix-sidebar-item-padding-top:                 10px;
$quote-affix-sidebar-item-padding-bottom:              10px;
$quote-affix-sidebar-item-margin-top:                  10px;
$quote-affix-xs-sm-btn-close-summary-right:            20px;
$quote-affix-xs-sm-btn-close-summary-top:              10px;
$quote-affix-sidebar-purcahse-summary-h4-font-size:    36px;
$confirmation-forms-font-size:                         18px;
$confirmation-forms-padding:                           0;

//------------------------------------
// Confirmation Screen
//------------------------------------
$confirmation-panel-body-color:			$primary-dark-blue;
$confirmation-step-media-border:		$primary-light-gray;
$confirmation-step-media-background:	$primary-light-gray;
$confirmation-step-media-circle-color:	$primary-dark-blue;
$confirmation-step-media-heading-color:	$primary-dark-blue;
$confirmation-list-border:				$primary-light-gray;
$confirmation-list-border-active:		$primary-dark-blue;
$confirmation-forms-color:				#ffffff;
$confirmation-forms-print-all:			$dairyland-light-copy;
$confirmation-forms-print-all-hover:	darken($dairyland-light-copy, 15%);

//---------------------------------------
// Cards
//---------------------------------------
$card-spacer-y:                     5px;
$card-spacer-x:                     10px;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 $primary;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       $primary;
$card-bg:                           transparent;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 ($grid-gutter-width / 2) !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;

//---------------------------------------
// Terms and Conditions
//---------------------------------------
$terms-padding: 30px;
$terms-min-height: 600px;

//---------------------------------------
// Colors
//---------------------------------------
$label-disabled-highlight:			$primary-gray;
$label-disabled-color:				$primary-gray;
$label-disabled-border:				1px solid $primary-gray;
$label-disabled-circle-background:	$dairyland-gray-copy;
$label-disabled-circle-color:		$dairyland-slate;

$label-active-highlight:			$dairyland-asphalt;
$label-active-color:				$dairyland-asphalt;
$label-active-border:				1px solid $secondary-yellow;
$label-active-circle-background:	$dairyland-asphalt;
$label-active-circle-color:			$dairyland-white;

$label-inactive-highlight: 			$dairyland-light-copy;
$label-inactive-color:				$dairyland-light-copy;
$label-inactive-border:				1px solid $dairyland-light-copy;
$label-inactive-circle-background:	$dairyland-light-copy;
$label-inactive-circle-color:		$dairyland-slate;

$secondary-yellow-button-color:		$white;
$secondary-yellow-button-bg:		$secondary;
$secondary-yellow-button-border:	$secondary;

$link-reset-coverage:				$secondary;
$quote-coverage-edit-link-color:    $dairyland-alt-link;

$striped-row-background: 		$brand-gray-light;
$content-divider-color: 		#999999;

//-----------------------
// Coverage Page
//-----------------------
$coverage-form-margin-right-sm: 0px;
$coverage-form-margin-left-sm: 0px;
$coverage-form-margin-right: 0px;

//------------------------
// Driver Assignment page
//------------------------
$driver-assignment-font-family: $font-family-geogrotesque-semi-bold;

//-----------------------
// Page scroll
//-----------------------
$scrolled-padding-bottom: 0.9rem;
$scrolled-padding-top: 0.6rem;

//-----------------
// Modal
//-----------------
$modal-content-min-height: 150px;

//-----------------
// Form Controls
//-----------------
$formquestion-copy-fontsize: 18px;
$dynamic-repeatable-padding-left: 15px;
$dynamic-repeatable-font-size: 18px;
$dynamic-repeatable-font-family: $font-family-geogrotesque-semi-bold;

//--------------------------------------------
// Misc that should get cleaned up eventually
//--------------------------------------------
$quote-question-label-color:        $body-color;
$readonly-input-color:              rgba(70, 65, 80, 0.8);
$empty-select-color:				#666;
$body-background-color:             white;
$summary-card-active-box-shadow:    0px 0px 3px 0px #000;
$add-entity-submit-font-size:       20px;
$add-entity-submit-margin-right:    15px;
$option-message-color:              $dairyland-dusk;
$discount-field-h3-font-size:       20px;
$discount-field-h3-font-family:		$font-family-geogrotesque-semi-bold;
$discount-field-h3-color:           $formheader-color;
$coverage-category-label-border-top: 1px solid $primary;
$ajax-loading-icon-image: url("#{$context}/images/common/ajax-loader-custom.gif");
$ajax-loading-width: 480px;
$ajax-loading-height: 0;
$ajax-loading-background: none repeat scroll 0 center rgba(0, 0, 0, 0);
$ajax-loading-icon-opacity: 1;
$term-best-price-color: $secondary-bright-blue;
$quote-affix-xs-sm-background: rgba(54,93,118,1);
$confirmation-disclaimer-content:  1px;
$ui-loader-saving-indicator-color: $dairyland-light-copy;
$affix-quote-summary-text-align: center;
$quote-summary-header-text-align: center;
$modal-control-label-color: $formquestion-copy-color;
$quote-affix-label-circle-border: 2px solid;
$icon-checkmark-font-size: 28px;
$remove-padding-xs-screen-padding: 0px 15px;
$quote-container-min-height: 0;
$radio-button-error-border: 1px solid darken($danger, 5%);
$pay-today-currency-symbol-color: $primary;
$confirmation-success-check-font-size: 20px;
$print-all-button-container-text-align: right;
$label-font-size-mobile: 18px;
$label-font-size-desktop: $font-size-lg;
$container-affix-top: -71px;
$container-affix-top-sm: 0;
$hanging-paragraph : 15px;